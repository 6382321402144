import React from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import {
  DraftBadge,
} from "../components/style"
import {
  Card,
  Cards,
} from "../components/card"
import { Link } from "gatsby"
import { Image } from "../blocks/image"

const Excerpt = styled.div`
  font-weight: 50;
  color: ${props => props.theme.color.foreground};
  margin: 0 1rem 1rem 1rem;
`

const Title = styled.h2`
  font-size: 1.2em;
  color: ${props => props.theme.color.foreground};
  margin: 1rem 1rem .5rem 1rem;
`

const StyledLink = styled(Link)`
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

export function Arrangements({ data }) {

    const arrangements = useStaticQuery(graphql`
        query {
            arrangements: allPagesJson(
                filter: { published: { eq: true }, type: { eq: "arrangement" } }
              ) {
                edges {
                  node {
                    id
                    path
                    published
                    title
                    hero {
                      image {
                        childImageSharp {
                          fluid(quality: 70, maxWidth: 487) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    childrenPagesJsonBlockMarkdown {
                      childMarkdownRemark {
                        excerpt
                      }
                    }
          
                    rawJson
                    fileRelativePath
                  }
                }
            }
        }
    `)

  return (
    <Cards>
        {data && data.arrangements && data.arrangements.map(arrangement => {
            // Set to known value by default, in case it fails
            var item = ""
            var found = false

            for (const a of arrangements.arrangements.edges) {
                if (a.node.path === arrangement.link) {
                    item = a
                    found = true
                    break
                }
            }
            
            if (!found) {
                console.log(arrangement.link + " not found")
                return true
            }

            if (!item.node.published) {
                console.log(arrangement.link + " is not published, so hidden from the arrangements list")
                return true
            }

            const sizes = "(max-width: 599px) calc(100vw - 1rem - 2.5rem - 2.5rem - 1rem), (max-width: 1015px)  calc(50vw - 3rem - 2rem - 2.5rem - 1em), 338px"
            
            // Replace the default sizes attribute
            if (item.node.hero && item.node.hero.image) {
                item.node.hero.image.childImageSharp.fluid.sizes = sizes
            }

            var excerpt = ""

            for (const block of item.node.childrenPagesJsonBlockMarkdown) {
              if (block.childMarkdownRemark.excerpt !== "") {
                excerpt = block.childMarkdownRemark.excerpt
                break
              }
            }

            const itemJsonData = JSON.parse(item.node.rawJson)

            return (
                <Card article key={item.node.id}>
                    <StyledLink to={item.node.path}>
                      {itemJsonData.draft && <DraftBadge>Draft</DraftBadge>}
                      {item.node.hero && item.node.hero.image && <Image data={item.node.hero} />}
                      <Title>{item.node.title}</Title>
                      <Excerpt>{excerpt}</Excerpt>
                    </StyledLink>
                </Card>
              )
            })
          }
    </Cards>
  )
}

export const ArrangementsBlock = {
  label: "Arrangements",
  name: "arrangements",
  fields: [
    {
      label: "Arrangements",
      name: "arrangements",
      component: "group-list",
      itemProps: item => ({
        label: item.label,
      }),
      fields: [
        {
          label: "Label",
          name: "label",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Link",
          name: "link",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
      ]
    }
  ]
}
