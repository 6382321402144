import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import get from "lodash.get"

export function TitleImage({ page, data }) {
  const centered = data.center ? data.center : false
  const imageLeft = data.imageleft ? true : false
  const contentLeft = data.imageleft ? false : true

  const sizes = "(max-width: 599px) calc(100vw - 1rem - 2.5rem - 2.5rem - 1rem), (max-width: 1015px)  calc(50vw - 3rem - 2rem - 2.5rem - 1em), 338px"
  
  return (
    <Wrapper>
    <SplitView left={imageLeft}>
      {data.image &&
        data.image.childImageSharp && (
          <ImageWrapper>
            <Img fluid={{...data.image.childImageSharp.fluid, sizes: sizes}} />
          </ImageWrapper>
        )}
      </SplitView>
      <SplitView left={contentLeft}>
        <StyledTitle center={centered}>
          {data && data.title ? data.title : page.title ? page.title : ""}
        </StyledTitle>
        {data && data.underline && <Hr center={centered} />}
      </SplitView>
    </Wrapper>
  )
}

const StyledTitle = styled.h2`
  font-size: 2.2em;
  line-height: 1.2;
  word-spacing: 1px;
  font-weight: 700;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

const Hr = styled.hr`
  margin: 2.2rem 0;

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: row;
  }
`

const SplitView = styled.div`
  float: left;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    width: calc(50% - 1em);
    order: ${props => props.left ? 1 : 2};
  }
`

export const TitleImageBlock = {
  label: "Title Image",
  name: "title image",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
    imageleft: true,
    image: "",
  },
  fields: [
    { name: "title", label: "Title", component: "text" },
    { name: "center", label: "Center", component: "toggle" },
    { name: "underline", label: "Underline", component: "toggle" },
    { name: "imageleft", label: "Image Left", component: "toggle" },
    {
        label: "Image",
        name: "image",
        component: "image",
        parse: filename => `../images/${filename}`,
        uploadDir: () => `/content/images/`,
        previewSrc: (formValues, fieldProps) => {
          const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
          const imageNode = get(formValues, pathName)
          if (!imageNode || !imageNode.childImageSharp) return ""
          return imageNode.childImageSharp.fluid.src
        },
      },
    ],
}

const ImageWrapper = styled.div`
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    padding-bottom: 1.5rem;
  }
`
