import React from "react"
import styled from "styled-components"

export function Corona({data}) {
  return (
    <>
    </>
    // <StyledContent>
    //   {data && data.content ? data.content : "N.B. Voor alle arrangementen gelden vanzelfsprekend de actuele coronamaatregelen, overleg met ons welke mogelijkheden er zijn."}
    // </StyledContent>
  )
}

const StyledContent = styled.div`
  border: 2px solid rgb(160, 206, 78);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(160, 206, 78, 0.2);
`

export const CoronaBlock = {
  label: "Corona",
  name: "corona",
  key: "test",
  defaultItem: {
    text: "",
  },
  fields: [
    { name: "content", label: "Text", component: "text" },
  ],
}
