import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import get from "lodash.get"

export function DoubleImage({ data }) {
  const sizes = "(max-width: 599px) calc(50vw - 1rem - 2.5rem - 1em), (max-width: 1015px)  calc(50vw - 3rem - 2rem - 2.5rem - 1em), 338px"
  
  return (
    <DoubleImageWrapper>
      {data.image &&
      data.image.childImageSharp && (
        <ImageWrapper>
          <Img fluid={{...data.image.childImageSharp.fluid, sizes: sizes}} />
        </ImageWrapper>
      )}
      {data.image2 &&
      data.image2.childImageSharp && (
        <ImageWrapper>
          <Img fluid={{...data.image2.childImageSharp.fluid, sizes: sizes}} />
        </ImageWrapper>
      )}
    </DoubleImageWrapper>
  )
}

const DoubleImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  overflow: hidden;
  width: calc(50% - 1em);
`

export const DoubleImageBlock = {
  label: "Double Image",
  name: "image",
  key: "test",
  defaultItem: {
    image: "",
    image2: "",
  },
  fields: [
    {
      label: "Image 1",
      name: "image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode || !imageNode.childImageSharp) return ""
        return imageNode.childImageSharp.fluid.src
      },
    },
    {
      label: "Image 2",
      name: "image2",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode || !imageNode.childImageSharp) return ""
        return imageNode.childImageSharp.fluid.src
      },
    },
  ],
}
