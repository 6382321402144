import React from "react"
import styled from "styled-components"

import { DraftBadge } from "../components/style"
import { Card, Cards } from "../components/card"
import { useStaticQuery, graphql, Link} from "gatsby"
import { Image } from "../blocks/image"

import { LinkButton } from "../components/style"

export const Highlights = ({ data2, html }) => {

  const data = useStaticQuery(graphql`
    query {
      arrangements: allPagesJson(
        filter: { 
          path: { 
            in: [
              "/arrangementen/alpaca",
              "/arrangementen/pannenkoeken-bakken",
              "/arrangementen/kinderfeestje"
            ]
          }
        } 
      ) {
      edges {
        node {
        id
        path
        title
        hero {
          image {
            childImageSharp {
              fluid(quality: 70, maxWidth: 487) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`)

    return (
        <>
        <Cards>
          {data.arrangements && 
            data.arrangements.edges.map(item => {
              const sizes = "(max-width: 599px) calc(100vw - 1rem - 2.5rem - 2.5rem - 1rem), (max-width: 1015px) calc((100vw - 3rem - 2rem - 2.5rem - 1em - 1em - 2.5rem - 2rem - 3rem) / 3), 216px"
              
              // Replace the default sizes attribute
              item.node.hero.image.childImageSharp.fluid.sizes = sizes

              return (
                  <NarrowCard article key={item.node.id}>
                  {item.node.draft && <DraftBadge>Draft</DraftBadge>}
                      <StyledLink to={item.node.path}>
                      <Image data={item.node.hero} />
                      <Title>{item.node.title}</Title>
                      </StyledLink>
                      <LinkButton
                        to={item.node.path}
                      >Lees meer</LinkButton>
                  </NarrowCard>
              )
            })
        }
        </Cards>
        <LinkButton
            to={'/arrangementen'}
            primary={true}
        >Bekijk al onze arrangementen<span>&nbsp;&nbsp;→</span></LinkButton>
        </>
    )
}

const NarrowCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    flex: 1 0 calc(33% - 1em);
  }
`

const Title = styled.h2`
  font-size: 1em;
  color: ${props => props.theme.color.foreground};
  margin: 1rem 1rem .5rem 1rem;
`

const StyledLink = styled(Link)`
   &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

export const HighlightsBlock = {
  label: "Highlights",
  name: "highlights",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
    imageleft: true,
    image: "",
  },
  fields: [
    { name: "content", label: "Content", component: "markdown" },
    { name: "center", label: "Center", component: "toggle" },
    { name: "imageleft", label: "Image Left", component: "toggle" },
    ],
}
