import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import get from "lodash.get"

export function ContentImage({ data, html }) {
  const centered = data.center ? data.center : false
  const imageLeft = data.imageleft ? true : false
  const contentLeft = data.imageleft ? false : true

  const sizes = "(max-width: 599px) calc(100vw - 1rem - 2.5rem - 2.5rem - 1rem), (max-width: 1015px)  calc(50vw - 3rem - 2rem - 2.5rem - 1em), 338px"
  
  return (
    <Wrapper>
    <SplitView left={imageLeft}>
      {data.image &&
        data.image.childImageSharp && (
          <ImageWrapper>
            <Img fluid={{...data.image.childImageSharp.fluid, sizes: sizes}} />
          </ImageWrapper>
        )}
      </SplitView>
      <SplitView left={contentLeft}>
        <StyledContent
          center={centered}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        >
      </StyledContent>
    </SplitView>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: row;
  }
`

const SplitView = styled.div`
  float: left;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    width: calc(50% - 1em);
    order: ${props => props.left ? 1 : 2};
  }
`

const StyledContent = styled.div`
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

export const ContentImageBlock = {
  label: "Content Image",
  name: "content image",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
    imageleft: true,
    image: "",
  },
  fields: [
    { name: "content", label: "Content", component: "markdown" },
    { name: "center", label: "Center", component: "toggle" },
    { name: "imageleft", label: "Image Left", component: "toggle" },
    {
        label: "Image",
        name: "image",
        component: "image",
        parse: filename => `../images/${filename}`,
        uploadDir: () => `/content/images/`,
        previewSrc: (formValues, fieldProps) => {
          const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
          const imageNode = get(formValues, pathName)
          if (!imageNode || !imageNode.childImageSharp) return ""
          return imageNode.childImageSharp.fluid.src
        },
      },
    ],
}

const ImageWrapper = styled.div`
  overflow: hidden;
`
